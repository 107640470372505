import React from "react";
import Logo from "../../images/logo.png"

export default class NavBar extends React.Component {
  componentDidMount () {
      const script = document.createElement("script");

      script.src = "/new-age.js";
      script.async = true;

      document.body.appendChild(script);
  }

  render(){
    return (<React.Fragment>
      <nav className="navbar navbar-expand-lg navbar-light fixed-top" id="mainNav" >
      <div className="container">
        <a className="navbar-band navbar-brand js-scroll-trigger navbar-image" href="/#home">
          <img src={Logo} className="card-img navbar-logo" alt="globcon"></img>
        </a>

        <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
          <i className="fa fa-bars"></i>
        </button>
        <div className="collapse navbar-collapse" id="navbarResponsive">
          <ul className="navbar-nav ml-auto">
            <li className="nav-item">
              <a className="nav-link js-scroll-trigger" href="/#home">Home</a>
            </li>
            <li className="nav-item">
              <a className="nav-link js-scroll-trigger" href="/#features">Highlights</a>
            </li>
            <li className="nav-item">
              <a className="nav-link js-scroll-trigger" href="/#about">About</a>
            </li>
            <li className="nav-item">
              <a className="nav-link js-scroll-trigger" href="/tutorials">Tutorials</a>
            </li>
            <li className="nav-item">
              <a className="nav-link js-scroll-trigger" href="/#download">Downloads</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    </React.Fragment>)
  }
}
