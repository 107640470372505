import React, { Component } from "react";
import GlobalView from "../../images/globalview.jpg";
import "html5-device-mockups/dist/device-mockups.min.css"

export default class Landing extends Component {
  render() {
    return (
      <header className="masthead" id="home">
      <div className="container h-100">
        <div className="row h-100">
          <div className="col-lg-7 my-auto">
            <div className="header-content mx-auto">
              <h1 className="mb-5">consistent and coherent control of professional entertainment equipment from different brands as if they were just one</h1>
            </div>
          </div>
          <div className="col-lg-5 my-auto">

            <div className="device-container">
              <div className="device-mockup macbook">
                <div className="device" data-device="MacbookPro" data-orientation="portrait" data-color="black">
                  <div className="screen">
                    <img src={GlobalView} className="img-fluid" alt=""></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    );
  }
}
