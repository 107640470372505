import React, { Component } from "react";
import LogoIcon from "../../images/logo_icon.jpg";

export default class Imprint extends Component {

  render() {
    return(
<section className="about" id="about">
      <div className="container">
        <div className="section-heading text-center">
          <h2>Imprint</h2>
          <hr></hr>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <p className="text-left">
				      <h4><b>Name und Adresse:</b></h4>
				        LGSF Engineering GmbH<br/>
				        Brabeckstr. 121<br/>
				        30539 Hannover<br/>
			</p>

      <p className="text-left">
				<h4><b>Telefon:</b></h4> +49 511 811 75 42
			</p>
			
			<p className="text-left">
				<h4><b>Email:</b></h4> info@globcon.pro
			</p>

			<p className="text-left">
				<h4><b>Unternehmensform:</b></h4> Gesellschaft mit beschränkter Haftung
			</p>
			
			<p className="text-left">
				<h4><b>Geschäftsführer:</b></h4> Stephan Flock
			</p>
			
			<p className="text-left">
				<h4><b>Handelsregistereintrag:</b></h4> Amtsgericht Hannover HRB 215904
			</p>
			
			<p className="text-left">
				<h4><b>USt.-ID:</b></h4> DE815724392 
			</p>

            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 text-center rounded mx-auto d-block">
              <img src={LogoIcon} className="rounded mx-auto d-block img-fluid" alt=""></img>
            </div>
          </div>
      </div>
    </section>
    );
  }
}
