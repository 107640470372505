import React, { Component } from 'react';
import Select from 'react-select'
 
export default class ActivitySelector extends Component {
  constructor(props) {
    super(props)
 
    this.options = [
      {
        label: "Live Events",
        value: "Live Events"
      },
      {
        label: "AV",
        value: "AV"
      },
      {
        label: "Theatre",
        value: "Theatre"
      },
      {
        label: "Broadcast",
        value: "Broadcast"
      },
      {
        label: "Theme Parks",
        value: "Theme Parks"
      },
      {
        label: "Education",
        value: "Education"
      },
      {
        label: "Manufacturer",
        value: "Manufacturer"
      },
      {
        label: "Other",
        value: "Other"
      }
    ]
 
    this.state = {
      options: this.options,
      value: this.options[0],
    }
  }
 
  changeHandler = value => {
    this.setState({ value })
  }
 
  render() {
    return (
      <Select
        options={this.state.options}
        value={this.state.value}
        onChange={this.changeHandler}
      />
    )
  }
}