import React, { Component } from "react";
import Icon1 from "../../images/icon1.png";
import Icon2 from "../../images/icon2.png";
import Icon3 from "../../images/icon3.png";
import Icon4 from "../../images/icon4.png";

export default class Features extends Component {
  render() {
    return (
      <section className="features" id="features">
      <div className="container">
        <div className="section-heading text-center">
          <h2>Unlimited control</h2>
          <hr></hr>
        </div>
        <div className="row">
          <div className="col-lg-12 my-auto">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-3">
                  <div className="feature-item">
                    <img src={Icon1} className="text-primary feature-icon" alt=""></img>
                    <h3>Global Control</h3>
                    <p className="text-muted">Different brands managed as if they were just one!</p>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="feature-item">
                    <img src={Icon2} className="text-primary feature-icon" alt=""></img>
                    <h3>Control Anywhere</h3>
                    <p className="text-muted">Locally connected devices shared via Ethernet!</p>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="feature-item">
                    <img src={Icon3} className="text-primary feature-icon" alt=""></img>
                    <h3>Easy to Use</h3>
                    <p className="text-muted">No complicated protocols, no custom programming, just plug-and-play!</p>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="feature-item">
                    <img src={Icon4} className="text-primary feature-icon" alt=""></img>
                    <h3>Free to Use</h3>
                    <p className="text-muted">No costs for the users, download, install and enjoy!</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    );
  }
}
