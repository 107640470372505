import React, { Component } from "react";

export default class Download extends Component {
  render() {
    return (
      <section className="download" id="download">
      <div className="download-content">
        <div className="container">
          <div className="col-lg-5 m-auto">
            <a href="https://storage.googleapis.com/globcon-web-api.appspot.com/globcon-1.14.1.exe" className="font-weight-bold text-center btn btn-primary btn-lg btn-block btn-xxl js-scroll-trigger">DOWNLOAD for<br/>Windows</a>
            <a href="https://storage.googleapis.com/globcon-web-api.appspot.com/globcon-1.14.1.dmg" className="font-weight-bold text-center btn btn-primary btn-lg btn-block btn-xxl js-scroll-trigger mt-4">DOWNLOAD for<br/>MacOS (Intel)</a>
            <a href="https://storage.googleapis.com/globcon-web-api.appspot.com/globcon-silicon-1.14.1.dmg" className="font-weight-bold text-center btn btn-primary btn-lg btn-block btn-xxl js-scroll-trigger mt-4">DOWNLOAD for<br/>MacOS (Apple Silicon)</a>
          </div>
            </div>
            </div>
      <div className="overlay"></div>
    </section>
    );
  }
}