import React, { Component } from "react";
import LogoIcon from "../../images/logo_icon.jpg";

export default class SubmissionSuccessful extends Component {

  render() {
    return(
<section class="about" id="about">
      <div class="container">
        <div class="section-heading text-center">
          <h2>Submission successful</h2>
          <hr></hr>
        </div>
          <div class="row">
            <div class="col-lg-6 text-center rounded mx-auto d-block">
			We have sent you an email with a download link.
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 text-center rounded mx-auto d-block">
              <img src={LogoIcon} class="rounded mx-auto d-block img-fluid mt-5" alt=""></img>
            </div>
          </div>
      </div>
    </section>
    );
  }
}
