import React, { Component } from "react";
import LogoIcon from "../../images/logo_icon.jpg";

export default class About extends Component {
  render() {
    return (
      <section className="about" id="about">
      <div className="container">
        <div className="section-heading text-center">
          <h2>About</h2>
          <hr></hr>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <p>globcon is a <b>glob</b>al <b>con</b>trol software platform for the management of professional entertainment production equipment, available for download at no cost. globcon was conceived and designed from the end-user’s hands-on perspective allowing consistent and coherent standardized control of multiple types of equipment from different manufacturers as if they were just one. globcon’s mission is to offer to professional users a continually evolving powerful tool able to manage any type or size of production environment in a contemporary, flexible, ergonomic and cost-effective way.</p>
            <p>Available now for Mac and PC, globcon is a ”plug and play” solution enabling multiple users to simultaneously share the control of locally-connected devices via a standard Ethernet infrastructure.</p>
            <p>German pro audio manufacturer, <a href="http://www.directout.eu"><b>DirectOut GmbH</b></a>, was the first adopter of globcon and the complete DirectOut product portfolio is fully supported by globcon. In 2023, an increasing number of differing devices from multiple manufacturers is being added progressively. A public version of the software is available for download at no cost, providing integrated control and management of any number of DirectOut devices via an Ethernet network, USB and MIDI ports.</p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 text-center rounded mx-auto d-block">
            <img src={LogoIcon} className="rounded mx-auto d-block img-fluid" alt=""/>
          </div>
        </div>
      </div>
    </section>
    );
  }
}
