import React, { Component } from "react";
import About from "./About";
import Landing from "./Landing";
import Features from "./Features";
import Download from "./Download";

export default class Home extends Component {
  render() {
    return (
      <React.Fragment>
        <Landing />
        <Features />
        <About />
        <Download />
      </React.Fragment>
    );
  }
}
