import React, { Component } from "react";

export default class Tutorials extends Component {

  links = [
    "https://www.youtube.com/embed/imGAPha-w2k", 
    "https://www.youtube.com/embed/OqrDK8QKewo", 
    "https://www.youtube.com/embed/emKaP1TkvO4", 
    "https://www.youtube.com/embed/kQCxlJKayfA", 
    "https://www.youtube.com/embed/QJMUOpyhmhY", 
    "https://www.youtube.com/embed/ujDoKTYs4y0", 
    "https://www.youtube.com/embed/XhM-Bk-LAxg", 
    "https://www.youtube.com/embed/SMDd5Syy3ug", 
    "https://www.youtube.com/embed/6HK3m1a-Zsg", 
    "https://www.youtube.com/embed/Rryg3e78_FA", 
    "https://www.youtube.com/embed/uskhmlnJp0M", 
    "https://www.youtube.com/embed/o1RvSt1gu2w", 
    "https://www.youtube.com/embed/NzDc-SwQIw0", 
    "https://www.youtube.com/embed/Zlfbomf1_HI", 
    "https://www.youtube.com/embed/VFMj_h3GHc4", 
    "https://www.youtube.com/embed/GdrnXrtEqHA", 
    "https://www.youtube.com/embed/RFITplWWie4", 
    "https://www.youtube.com/embed/y7246kX-0LY", 
    "https://www.youtube.com/embed/AXNC8Mb0l00", 
    "https://www.youtube.com/embed/GWdJLWvQ0us", 
    "https://www.youtube.com/embed/u2MUNOw0QVs", 
    "https://www.youtube.com/embed/UWdMUhgcPIk", 
    "https://www.youtube.com/embed/S2rij4gM5L8",
    "https://www.youtube.com/embed/Mp6YcksXtIs",
    "https://www.youtube.com/embed/dAOXVOVxdWk",
    "https://www.youtube.com/embed/QG2DYf1eNuY",
    "https://www.youtube.com/embed/rpcmMBpjKcQ",
    "https://www.youtube.com/embed/C0MIECrWrDw",
    "https://www.youtube.com/embed/mgsTLfGLKss"
  ];

  renderTutorials(){
    return this.links.map((link, index) => {
      return (
        <div className="row tutorial-row" key={"Container" + index}>
          <div className="tutorial-item col-lg-8 embed-responsive embed-responsive-16by9 mx-auto d-block">
            <iframe title={index} className="embed-responsive-item" src={link} allowFullScreen/>
          </div>
        </div>
      );
    });
  }

  render() {
    return (
      <section className="tutorial" id="tutorial">
      <div className="container">
        <div className="section-heading text-center">
          <h2>Tutorials</h2>
          <hr></hr>
        </div>
        {
          this.renderTutorials()
        }
      </div>
    </section>
    );
  }
}
