import React, { Component } from "react";
import LogoIcon from "../../images/logo_icon.jpg";

export default class SomethingWentWrong extends Component {

  render() {
    return(
<section className="about" id="about">
      <div className="container">
        <div className="section-heading text-center">
          <h2>Oops!</h2>
          <hr></hr>
        </div>
          <div className="row">
            <div className="col-lg-6 text-center rounded mx-auto d-block font-weight-bold">
			Something went wrong...
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 text-center rounded mx-auto d-block mt-4">
			Please, retry later!
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 text-center rounded mx-auto d-block mt-2">
          If you need further help, contact us at <a href="mailto:support@globcon.pro">support@globcon.pro</a>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 text-center rounded mx-auto d-block">
              <img src={LogoIcon} class="rounded mx-auto d-block img-fluid mt-5" alt=""></img>
            </div>
          </div>
      </div>
    </section>
    );
  }
}
