import 'bootstrap/dist/css/bootstrap.min.css';

import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import "./App.scss";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Home from "./components/Home";
import Login from "./components/Login";
import Tutorials from "./components/Tutorials";
import Download from "./components/Download";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Imprint from "./components/Imprint";
import SubmissionSuccesful from "./components/SubmissionSuccessful";
import SomethingWentWrong from "./components/SomethingWentWrong";

//TODO Web Template Studio: Add routes for your new pages here.
class App extends Component {
  render() {
    return (
      <React.Fragment>
        <NavBar />
        <div className = "mt-4">
        <Switch>
          <Route exact path = "/" component = { Home } />
          <Route path = "/download" component = { Download } />
          <Route path = "/tutorials" component = { Tutorials } />
          <Route path = "/login" component = { Login } />
          <Route path = "/privacypolicy" component = { PrivacyPolicy } />
          <Route path = "/imprint" component = { Imprint } />
          <Route path = "/submission_successful" component = { SubmissionSuccesful } />
          <Route path = "/ops" component = { SomethingWentWrong } />
        </Switch>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}
export default App;
