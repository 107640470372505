import React, { Component } from "react";
import CountrySelector from './CountrySelector'
import ActivitySelector from './ActivitySelector'
import PrivacyPolicy from "../PrivacyPolicy";

export default class Download extends Component {

	constructor(props) {
		super(props);
		this.state = {
			isLoading: false 
		}

		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleSubmit(event) {
		event.preventDefault();
		const data = new FormData(event.target);
		
		this.setState({isLoading: true })

		fetch('/api/register', {
		  method: 'POST',
		  body: data
		}).then(response => {
			this.setState({ isLoading: false });
			console.log("Response status " + response.ok + " --> " + response.status)
			if(response.ok){
				this.props.history.push('/submission_successful');
			}
			else{
				this.props.history.push('/ops');
			}
		}).catch(error => {
			this.props.history.push('/ops');
		});
	}

  	render() {
		return (
		<section className="downloadpage">
			<div className="container col-lg-6">
				<div className="section-heading text-center">
					<h2>Download globcon</h2>
					<hr></hr>
				</div>

				<form onSubmit={this.handleSubmit}>
				<div className="form-group">
					<label htmlFor="firstName">First Name</label>
					<input type="text" className="form-control" id="firstName" name="firstName" required="required" placeholder="Enter your First Name"></input>
				</div>			
				<div className="form-group">
					<label htmlFor="lastName">Last Name</label>
					<input type="text" className="form-control" id="lastName" name="lastName" required="required" placeholder="Enter your Last Name"></input>
				</div>
				<div className="form-group">
					<label htmlFor="exampleInputEmail1">Email address</label>
					<input type="email" className="form-control" id="email" name="email" aria-describedby="emailHelp" required="required" placeholder="Enter email"></input>
					<small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
				</div>			
				<div className="form-group">
					<label htmlFor="companyName">Company</label>
					<input type="text" className="form-control" id="companyName" name="companyName" required="required" placeholder="Enter your company name"></input>
				</div>
				<div className="form-group">
					<label htmlFor="jobTitle">Job title</label>
					<input type="text" className="form-control" id="jobTitle" name="jobTitle" required="required" placeholder="Enter your job title"></input>
				</div>
				<div className="form-group">
					<label htmlFor="phoneNumber">Phone number</label>
					<input type="text" className="form-control" id="phoneNumber" name="phoneNumber" placeholder="Enter your phone"></input>
				</div>
				<div className="form-group">
					<label htmlFor="companyCity">Company city</label>
					<input type="text" className="form-control" id="companyCity" name="companyCity" required="required" placeholder="Enter your company city"></input>
				</div>
				
				
				<div className="form-group">
					<label htmlFor="companyCountry">Company Country</label>				
					<CountrySelector className="form-control" required="required" name="companyCountry">
					</CountrySelector>				
				</div>
						
				<div className="form-group">
					<label htmlFor="areaOfActivity">Area of activity</label>				
					<ActivitySelector className="form-control" required="required" name="areaOfActivity">
					</ActivitySelector>				
				</div>

				<div className="form-group">
					<div className="form-check">
						<input className="form-check-input" type="checkbox" value="True" id="newsletterCheck" name="newsletterCheck"></input>
						<label className="form-check-label" htmlFor="newsletterCheck">
							I agree to receive globcon's newsletter
						</label>
					</div>
					<div className="form-check">
			<div id="privacyPolicyArea" rows="6" readOnly>
				<PrivacyPolicy></PrivacyPolicy>
						</div>

						<input className="form-check-input" type="checkbox" value="True" required="required" id="privacyPolicyCheck" name="privacyPolicyCheck"></input>
						<label className="form-check-label" htmlFor="privacyPolicyCheck">
							I agree to globcon’s <a href="./privacypolicy" className="text-info">Privacy Policy</a>
						</label>
					</div>
				</div>

				<div className="form-group">
					<div className="text-center">
						<button type="submit" className="btn btn-primary" disabled={this.state.isLoading}>Submit</button>
					</div>
				</div>
			</form>
			</div>
		</section>
		);
	}
}
